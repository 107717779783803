import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestoreDb as db } from '../config/firebase';
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const DispatchHistory = () => {
    const [documents, setDocuments] = useState([]);
    const tableRef = useRef();

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'yangkhorDispatchFile'));
                const data = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDocuments(data);
            } catch (error) {
                console.error("Error fetching documents: ", error);
            }
        };

        fetchDocuments();
    }, []);

    const handleViewDocument = (base64File) => {
        try {
            if (!base64File.includes("base64,")) {
                throw new Error("Invalid Base64 string");
            }
            const [prefix, base64String] = base64File.split(',');
            const mimeType = prefix.match(/:(.*?);/)[1];
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        } catch (error) {
            console.error("Error handling Base64 document: ", error);
            alert("Failed to open document. The file might be corrupted or not a supported format.");
        }
    };

    const downloadPDF = async () => {
        const doc = new jsPDF('p', 'pt', 'a4');
        const tableElement = tableRef.current;
        
        const canvas = await html2canvas(tableElement);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = doc.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        doc.save('dispatch-history.pdf');
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <Sidebar />

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                <TopBar />

                {/* Main Content Area */}
                <main className="flex-1 p-4">
                    <h2 className="text-2xl font-semibold mb-4">Dispatch History</h2>

                    {/* Button to Download as PDF */}
                    <button
                        className="mb-4 bg-blue-600 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-700"
                        onClick={downloadPDF}
                    >
                        Download as PDF
                    </button>

                    <div ref={tableRef} className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="py-2 px-4 border-b">Dispatch Number</th>
                                    <th className="py-2 px-4 border-b">Upload Date</th>
                                    <th className="py-2 px-4 border-b">Details</th>
                                    <th className="py-2 px-4 border-b">Uploaded By</th> {/* New column */}
                                    <th className="py-2 px-4 border-b">File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {documents.map((doc) => (
                                    <tr key={doc.id}>
                                        <td className="py-2 px-4 border-b">{doc.dispatchNumber}</td>
                                        <td className="py-2 px-4 border-b">
                                            {doc.uploadDate ? new Date(doc.uploadDate.seconds * 1000).toLocaleDateString() : 'N/A'}
                                        </td>
                                        <td className="py-2 px-4 border-b">{doc.details}</td>
                                        <td className="py-2 px-4 border-b">{doc.dispatchUploadedBy || 'N/A'}</td> {/* Display email */}
                                        <td className="py-2 px-4 border-b">
                                            {doc.file ? (
                                                <button
                                                    className="text-blue-500 hover:underline"
                                                    onClick={() => handleViewDocument(doc.file)}
                                                >
                                                    View Document
                                                </button>
                                            ) : (
                                                'No File'
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DispatchHistory;
