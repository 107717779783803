import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { addDoc, collection } from "firebase/firestore";
import { firestoreDb as db } from "../config/firebase";
import { getAuth } from "firebase/auth"; // Import Firebase Auth

const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result; // Use the full base64 string, including MIME type
            resolve(base64String);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file); // This gives the full data URI, including MIME type
    });
};

const DispatchForm = () => {
    const [formUploading, setFormUploading] = useState(false);
    const [formData, setFormData] = useState({
        dispatchNumber: "",
        uploadDate: "",
        details: "",
        file: ""
    });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    // Get the currently logged in user's email
    const auth = getAuth();
    const user = auth.currentUser;
    const userEmail = user ? user.email : "unknown@example.com"; // Use the user's email

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormUploading(true);
        setShowSuccessAlert(false); // Reset alert visibility

        try {
            let convertedFile = "";
            if (formData.file) {
                convertedFile = await convertFileToBase64(formData.file);
            }

            // Add the user's email to the form data
            const formDataToSubmit = {
                ...formData,
                file: convertedFile,
                dispatchUploadedBy: userEmail, // Save the email of the uploader
            };

            // Save the data to Firestore
            await addDoc(collection(db, "yangkhorDispatchFile"), formDataToSubmit);
            setShowSuccessAlert(true);
            setFormData({
                dispatchNumber: "",
                uploadDate: "",
                details: "",
                file: ""
            });
        } catch (err) {
            console.error(err);
            alert("Error while uploading");
        } finally {
            setFormUploading(false);
        }
    };

    return (
        <div className="flex h-screen bg-gray-100 overflow-hidden">
            {/* Sidebar */}
            <Sidebar />

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                <TopBar />

                {/* Main Content Area */}
                <main className="flex-1 p-1 flex items-center justify-center">
                    <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-xl font-semibold mb-4">
                            Dispatch Documents
                        </h3>

                        {/* Success Alert Box */}
                        {showSuccessAlert && (
                            <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">Success!</strong>
                                <span className="block sm:inline"> Your form has been successfully uploaded.</span>
                                <span
                                    className="absolute top-0 bottom-0 right-0 px-4 py-3"
                                    onClick={() => setShowSuccessAlert(false)}
                                >
                                    <svg
                                        className="fill-current h-6 w-6 text-green-500"
                                        role="button"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <title>Close</title>
                                        <path d="M14.348 5.652a1 1 0 10-1.414-1.414L10 7.586 7.066 4.652a1 1 0 00-1.414 1.414L8.586 10l-2.934 2.934a1 1 0 101.414 1.414L10 12.414l2.934 2.934a1 1 0 001.414-1.414L11.414 10l2.934-2.934z" />
                                    </svg>
                                </span>
                            </div>
                        )}

                        <form className="space-y-2 w-full" onSubmit={handleFormSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label
                                        htmlFor="dispatchNumber"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Dispatch Number
                                    </label>
                                    <input
                                        id="dispatchNumber"
                                        name="dispatchNumber"
                                        onChange={(e) => {
                                            setFormData({ ...formData, dispatchNumber: e.target.value });
                                        }}
                                        type="text"
                                        value={formData.dispatchNumber}
                                        placeholder="YangKhor/year/document_number"
                                        className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="uploadDate"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Upload Date
                                    </label>
                                    <input
                                        id="uploadDate"
                                        name="uploadDate"
                                        type="date"
                                        value={formData.uploadDate}
                                        onChange={(e) => {
                                            setFormData({ ...formData, uploadDate: e.target.value });
                                        }}
                                        className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label
                                        htmlFor="message"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Details
                                    </label>
                                    <textarea
                                        id="details"
                                        rows="3"
                                        name="details"
                                        value={formData.details}
                                        placeholder="Your message here..."
                                        className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                        onChange={(e) => {
                                            setFormData({ ...formData, details: e.target.value });
                                        }}
                                    ></textarea>
                                </div>
                                <div className="flex flex-col items-center">
                                    <br></br>
                                    <label
                                        htmlFor="file"
                                        className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                        Dispatch File (Screenshots, docs, pdf)
                                    </label>
                                    <div className="flex items-center">
                                        <input
                                            id="file"
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    file: e.target.files[0],
                                                });
                                            }}
                                            name="file"
                                            type="file"
                                            className="hidden"
                                        />
                                        <label
                                            htmlFor="file"
                                            className="cursor-pointer bg-blue-600 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        >
                                            Select File
                                        </label>
                                    </div>
                                    {formData.file && (
                                        <span className="mt-2 text-sm text-gray-600 truncate">
                                            {formData.file.name}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <button
                                type="submit"
                                className={
                                    formUploading
                                        ? "cursor-not-allowed bg-gray-500 text-white px-4 py-2 rounded-lg disabled"
                                        : "px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                }
                                disabled={formUploading}
                            >
                                {formUploading ? "Uploading..." : "Submit"}
                            </button>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DispatchForm;
